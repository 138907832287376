<template>
  <div class="contact">
    <div class="wrapper">
      <Section1 />
      <Section2 />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from "@/components/pay/contact/Section1";
import Section2 from "@/components/pay/contact/Section2";

export default {
  name: "contact",
  components: {
    Section1,
    Section2,
  },
  head: {
    title: {
      inner: "Conctato"
    },
  },
  mounted() {
    this.$i18n.locale = "es";
  }
};
</script>