<template>
  <div id="section2">
    <div class="fondoGrisClaro" >
      <b-container fluid>
        <b-container>
          <br />
          <b-row>
            <b-col md="4" sm="12">
              <b-img :src="img" />
              <h3>
                {{$t("payTelefConct")}}
              </h3>
              <h3>
                {{$t("payAntCli")}}
              </h3>
              <a :href="'tel:' + numTelef">
                {{numTelef}}
              </a>
            </b-col>
            <b-col md="8" sm="12">
              <h2 class="text-left">
                {{$t("payTitulos")}}
              </h2>
              <h3 class="text-left">
                {{$t("payText")}}
              </h3>
              <br />
              <b-form>
                <b-row>
                  <b-col sm="12" class="pb-2 text-left">
                    {{$t("payCliente")}}
                    <b-form-radio-group id="id-cliente" 
                      v-model="form.cliente" 
                      :options="payClientes"
                      name="otrar"
                      stacked required
                      />
                  </b-col>
                  <b-col sm="12" class="pb-2">
                    <b-form-input id="id-empresa" 
                      v-model="form.empresa" required
                      :placeholder="$t('payEmpresa')"/>
                  </b-col>
                  <b-col sm="12" class="pb-2">
                    <b-form-textarea id="id-necesidad" 
                      v-model="form.necesidad" required
                      :placeholder="$t('payNecesidad')"/>
                  </b-col>
                  <b-col md="6" sm="12" class="pb-2">
                    <b-form-input id="id-nombre" 
                      v-model="form.nombre" required
                      :placeholder="$t('payNombre')"/>
                  </b-col>
                  <b-col md="6" sm="12" class="pb-2">
                    <b-form-input id="id-apellido" 
                      v-model="form.apellido" required
                      :placeholder="$t('payApellido')"/>
                  </b-col>
                  <b-col md="6" sm="12" class="pb-2">
                    <b-form-input id="id-email" 
                      v-model="form.email" required
                      :placeholder="$t('payEmail')"/>
                  </b-col>
                  <b-col md="6" sm="12" class="pb-2">
                    <b-form-input id="id-telef" 
                      v-model="form.telef" 
                      :placeholder="$t('payTelef')"/>
                  </b-col>
                  <b-col md="6" sm="12" class="pb-2">
                    <b-form-input id="id-poblacion" 
                      v-model="form.poblacion" required
                      :placeholder="$t('payPoblacion')"/>
                  </b-col>
                  <b-col md="6" sm="12" class="pb-2">
                    <b-form-input id="id-provincia" 
                      v-model="form.provincia" 
                      :placeholder="$t('payProvincia')"/>
                  </b-col>  
                  <b-col sm="12" class="pb-2">
                    <b-form-select id="id-gestor"
                    v-model="form.gestor"
                    :options="payGestor"
                    name="gestor"
                    />
                  </b-col>
                  
                  <b-col sm="12" class="pb-2 text-left">
                    <b-form-checkbox id="id-acept" 
                      v-model="form.acept" 
                      >
                      {{$t("payAcept")}}
                      </b-form-checkbox>
                  </b-col>
                  <b-col sm="12" class="pb-2 text-left">
                    <b-button @click="enviarFormulario()" class="buttonAzul">
                      {{$t("payBtn")}}
                    </b-button>
                  </b-col>
                </b-row>
                <br />
              </b-form>
            </b-col>
          </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    
  },
  "es":{
    "payTitulos": "Coméntanos tus necesidades.",
    "payText": "Te ofrecemos todas nuestras soluciones a tu medida. Ponte en contacto con nosotros y hablamos."
    , "payTelefConct":"TELÉFONO DE CONTACTO"
    , "payAntCli": "Atención al cliente"
    , "payCliente": "¿Es cliente de Pay?*"
    , "payClientes": ["SI","NO"]
    , "payEmpresa": "Nombre de tu empresa *"
    , "payNecesidad": "Cuentanos tus necesidades *"
    , "payNombre": "Nombres *"
    , "payApellido": "Apellidos *"
    , "payEmail": "Correo Electronico *"
    , "payTelef": "Teléfono *"
    , "payPoblacion": "Población"
    , "payProvincia": "Provincia"
    , "payGestor": [
      "Utilizas algún gestor de ecommerce?"
      , "Prestashop"
      , "Magento"
      , "WooCommerce"
      , "Otro"
    ]
    , "payAcept": "Accedo a recibir comunicaciones de productos de UniversalPay y servicios relacionados que puedan ser de mi interés (podrás darte de baja en cualquier momento). Ver https://www.aicad.es/politica-de-privacidad/"
    , "payBtn": "Enviar"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      img: require("@/assets/images/pay/logo_pay_color.png"),
      numTelef: "+34917407363",
      form: {
        cliente: null
        , empresa: "" 
        , necesidad: ""
        , nombre: ""
        , apellido: ""
        , email: ""
        , telef: ""
        , poblacion: ""
        , provincia: ""
        , gestor: 0
        , acept: null
      }
    };
  },
  methods: {
    enviarFormulario(){
      console.log(this.form);
      let variant = 'default';
      this.$bvToast.toast('Enviado formulario ... ', {
          title: this.$t('message.pay.page4'),
          variant: variant,
          autoHideDelay: 3000,
          solid: true
        });
      this.form = {
        cliente: null
        , empresa: "" 
        , necesidad: ""
        , nombre: ""
        , apellido: ""
        , email: ""
        , telef: ""
        , poblacion: ""
        , provincia: ""
        , gestor: 0
      }  
    }
  },
  computed: {
    payClientes() {
      return this.$t("payClientes");
    },
    payGestor() {
      return this.$t("payGestor");
    }
  }
};
</script>
