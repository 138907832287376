<template>
  <div id="section1">
    <div class="fondoFwhite" :style="'background-image: url(' + payImg + ');'">  
      <b-container fluid>
        <b-container>
          <br />
          <div class="pb-3">
            <h1>
              {{$t("title")}}
            </h1>
          </div>
          <div class="pb-3">
            <p>
              {{$t("txt")}}
            </p>
          </div>
          <br />
        </b-container>
      </b-container>
    </div>      
  </div>
</template>

<i18n>
{
  "en":{

  },
  "es":{
    "title": "Si estás interesado en contratar nuestros servicios o tienes alguna consulta comercial"
    , "txt": "Resolvemos tus dudas sobre cualquier forma de pago con tarjeta."
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/img_header_contacto.jpg")
    }
  }
};
</script>
